import "./SearchTips.scss";
import { Box } from "../styled-system/Box";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Divider } from "../layout/Divider";
import { getCountries, getVarieties } from "../../api/old-vine-registry-api";

export interface SearchTipsProps {
  searchCountry: (country: string) => void;
  searchAgeRange: (ageRange: string) => void;
  searchVariety: (variety: string) => void;
  className?: string;
}

function useCountries(): string[] {
  const [countries, setCountries] = useState<string[]>([]);
  useEffect(() => {
    getCountries().then((countries_) => setCountries(countries_));
  }, []);

  return countries;
}

function useVarieties(): string[] {
  const [varieties, setVarieties] = useState<string[]>([]);
  useEffect(() => {
    getVarieties().then((varieties_) => setVarieties(varieties_));
  }, []);

  return varieties;
}

export const SearchTips = ({
  searchCountry,
  searchAgeRange,
  searchVariety,
  className,
}: SearchTipsProps) => {
  const countries = useCountries();
  const varieties = useVarieties();
  const [country, setCountry] = useState("");
  const [variety, setVariety] = useState("");
  const [ageRange, setAgeRange] = useState("");

  return (
    <Box maxWidth="90rem" className={`description-text ${className || ""}`}>
      <div className="title-small">Search Tips</div>
      You can search by the following parameters: vineyard name, country,
      region, sub-region/appellation, grape variety, name of the owner, and name
      of a person of interest.
      <ul className="u-mt-medium u-ml-medium">
        <li>
          Most grape variety names are primarily in their English and French
          spelling (e.g. Mourvèdre vs Mataro or Monastrell)
        </li>
        <li>
          Most regions and appellations are rendered with their local spelling
          rather than being Anglicized (e.g. Cataluña vs Catalonia)
        </li>
        <li>
          Names from non-latin character sets have been transliterated to the
          best of our ability in conformance with best practices
        </li>
        <li>
          Many vineyard records are incomplete (e.g. do not list grape varieties
          or size). Help us correct these omissions!
        </li>
        <li>
          If you are interested in browsing lists of old vineyards by country or
          by age-range, use the dropdowns below.
        </li>
      </ul>
      <Divider />
      <Box>
        <span className="select-label u-mr-small">
          View vineyards by country:
        </span>
        <Box display="flex" alignItems="center">
          <Box width="200px" display="inline-block">
            <Form.Select
              className="input-text input-height"
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">Select country</option>
              {countries.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                        }).map((country) => (
                <option value={country} key={country}>
                  {country}
                </option>
              ))}
            </Form.Select>
          </Box>
          <button
            className="button u-ml-small"
            disabled={!country}
            onClick={() => searchCountry(country)}
          >
            Go
          </button>
        </Box>
      </Box>
      <Box mt="16px">
        <span className="select-label u-mr-small">
          View vineyards by variety:
        </span>
        <Box display="flex" alignItems="center">
          <Box width="200px" display="inline-block">
            <Form.Select
              className="input-text input-height"
              onChange={(e) => setVariety(e.target.value)}
            >
              <option value="">Select variety</option>
              {varieties.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                        }).map((variety) => (
                <option value={variety} key={variety}>
                  {variety}
                </option>
              ))}
            </Form.Select>
          </Box>
          <button
            className="button u-ml-small"
            disabled={!variety}
            onClick={() => searchVariety(variety)}
          >
            Go
          </button>
        </Box>
      </Box>
      <Box mt="16px">
        <span className="select-label u-mr-small">View vineyards by age:</span>
        <Box display="flex" alignItems="center">
          <Box width="200px" display="inline-block">
            <Form.Select
              className="input-text input-height"
              onChange={(e) => setAgeRange(e.target.value)}
            >
              <option value="">Select age range</option>
              <option value="35-54">35-54 years</option>
              <option value="55-74">55-74 years</option>
              <option value="75-94">75-94 years</option>
              <option value="75-114">95-114 years</option>
              <option value="115-134">115-134 years</option>
              <option value="135-154">135-154 years</option>
              <option value="155+">More than 155 years</option>
            </Form.Select>
          </Box>
          <button
            className="button u-ml-small"
            disabled={!ageRange}
            onClick={() => searchAgeRange(ageRange)}
          >
            Go
          </button>
        </Box>
      </Box>
    </Box>
  );
};
