import OldVines from "../../images/hero-old-vine.jpg";
import { Box } from "../../components/styled-system/Box";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./MainPage.scss";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../paths";
import { SearchTips } from "../../components/search-tips/SearchTips";
import { NavigationLink } from "../../components/styled-system/NavigationLink";
import { getDatabaseInfo } from "../../api/old-vine-registry-api";

const PicturePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${OldVines});
  background-position: center;
  background-repeat: no-repeat;
  height: 40rem;
  padding: 1rem;
  position: relative; /* Ensure the div positions its children correctly */
`;

const SearchPanel = styled.div`
  max-width: 788px;
  width: 100vw;
  padding-bottom: 24px;
  margin: 0 10px;
  background: white;
`;

function useLastUpdated(): string {
  const [lastUpdated, setLastUpdated] = useState("");
  useEffect(() => {
    getDatabaseInfo().then((databaseInfo) => setLastUpdated(databaseInfo));
  }, []);
  return lastUpdated;
}

export const MainPage = () => {
  const databaseInfo = useLastUpdated();
  const numberOfRecords = databaseInfo && databaseInfo["numberOfRecords"];
  const totalHectares = databaseInfo && databaseInfo["totalHectares"];
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  return (
    <Box display="flex" 
         flexDirection="column" 
         paddingBottom="50px">
      <PicturePanel className="picture-panel">
        <SearchPanel className="search-panel">
          <div className="cinzel-heading">
              The Old Vine Registry
          </div>
          <Box className="description-text-center">
            The world’s first crowdsourced global database of{" "}
            <NavigationLink to={Paths.oldVinesInfo}>
              <span className="description-text-link">
                living historic vineyard sites.
              </span>
            </NavigationLink>{" "}
            We hope that through greater awareness and attention these vineyards 
            and the wines they produce will survive and thrive. {" "}
            <NavigationLink to={Paths.about}>
              <span className="description-text-link">Learn more</span>.
            </NavigationLink>
          </Box>
          <Box display="flex" mt="20px">
            <Form.Control
              placeholder="Enter a vineyard name, winery name, region, grape variety, etc"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter" && searchText) {
                  navigate(`${Paths.vineyards}?search=${searchText}`);
                }
              }}
              className="input-text"
            />
            <button
              className="button u-ml-small"
              disabled={!searchText}
              onClick={() =>
                navigate(`${Paths.vineyards}?search=${searchText}`)
              }
            >
              Search
            </button>
          </Box>
          {databaseInfo && <Box className="description-text-center-stats">
            The database contains <b>{numberOfRecords}</b> vineyards and a total of <b>{totalHectares}</b> hectares
          </Box>}
        </SearchPanel>
      </PicturePanel>

      <Box className="competition-panel">
        <Box className="competition-heading" mb="1.6rem">
            10,000 Vineyards by 2027!
          </Box>
        <Box className="description-text-center-contest">
            Join the <b>Heritage Vine Hunt Contest</b>. Help us reach our goal of registering 10,000 vineyards by 26 June, 2027 and win fabulous prizes and (serious) bragging rights.
        </Box> 
        <button
            className="button u-ml-small"
            onClick={() =>
              navigate(`${Paths.contest}`)
            }
          >
            Learn More
          </button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="0 30px"
        mt="4rem"
      >
        <SearchTips
          className="u-mt-large"
          searchCountry={(country) =>
            navigate(`${Paths.vineyards}?country=${country}`)
          }
          searchVariety={(variety) =>
            navigate(`${Paths.vineyards}?variety=${variety}`)
          }
          searchAgeRange={(ageRange) =>
            navigate(`${Paths.vineyards}?age=${encodeURIComponent(ageRange)}`)
          }
        />
      </Box>
    </Box>
  );
};
