import "./VineyardsTable.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "../../components/styled-system/Box";
import Pagination from "react-bootstrap/Pagination";
import { Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Media } from "react-breakpoints";
import { SortOrder } from "../../components/table/table";
import { TableHeader } from "../../components/table/TableHeader";
import { SearchTips } from "../../components/search-tips/SearchTips";
import {
  getVineyards,
  getVineyardsByAge,
  getVineyardsByCountry,
  getVineyardsByVariety
} from "../../api/old-vine-registry-api";
import { Vineyard, VineyardsResultPage } from "../../api/models";
import { Paths } from "../../paths";

const pageSize = 500;

const Loading = (props: { loading: boolean }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="25px"
    mt="20px"
  >
    {props.loading && <Spinner animation="border" role="status" />}
  </Box>
);

const TD = ({ children }: { children: React.ReactNode }) => (
  <td>
    <Box height="100%" display="flex" alignItems="center">
      {children}
    </Box>
  </td>
);

function useVineyardsLoader(queryOn: { text?: string; age?: string }) {
  const [vineyards, setVineyards] = useState<Vineyard[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = () => {
    let result = {};
    if (searchParams.has("search")) {
      result["search"] = searchParams.get("search");
    }
    if (searchParams.has("country")) {
      result["country"] = searchParams.get("country");
    }
    if (searchParams.has("variety")) {
      result["variety"] = searchParams.get("variety");
    }
    if (searchParams.has("age")) {
      result["age"] = searchParams.get("age");
    }
    return result;
  };

  const loadData = async (
    queryOn: { text?: string; 
               age?: string; 
               country?: string; 
               variety?: string},
    orderBy = "name",
    order: SortOrder = "asc",
    page = 0
  ) => {
    setLoading(true);
    let vineyardsResultPage: VineyardsResultPage;
    const vineyardQueryBase = {
      field: orderBy,
      order,
      pageNumber: page,
      pageSize,
    };
    if (queryOn.age) {
      vineyardsResultPage = await getVineyardsByAge({
        ...vineyardQueryBase,
        searchValue: queryOn.age,
      });
    }
    else if (queryOn.country) {
        vineyardsResultPage = await getVineyardsByCountry({
          ...vineyardQueryBase,
          searchValue: queryOn.country,
        });
    } 
    else if (queryOn.variety) {
      console.log("getting vineyards by variety")
      vineyardsResultPage = await getVineyardsByVariety({
        ...vineyardQueryBase,
        searchValue: queryOn.variety,
      });
    } else {
      vineyardsResultPage = await getVineyards({
        ...vineyardQueryBase,
        searchText: queryOn.text,
      });
    }
    setLoading(false);
    setVineyards(vineyardsResultPage.content || []);
    setFirstLoad(false);
    setTotalResults(vineyardsResultPage.totalElements);
    setTotalPages(vineyardsResultPage.totalPages);
    setCurrentPage(page);
  };

  const nextPage = (orderBy = "name", order: SortOrder = "asc") => {
    if (currentPage === totalPages - 1) {
      return;
    }
    const next = currentPage + 1;
    setCurrentPage(next);
    setSearchParams({ ...currentParams(), page: `${next + 1}` });
    loadData(queryOn, orderBy, order, next);
  };

  const prevPage = (orderBy = "name", order: SortOrder = "asc") => {
    if (currentPage === 0) {
      return;
    }
    const next = currentPage - 1;
    setCurrentPage(next);
    setSearchParams({ ...currentParams(), page: `${next + 1}` });
    loadData(queryOn, orderBy, order, next);
  };

  const lastPage = (orderBy = "name", order: SortOrder = "asc") => {
    const last = totalPages - 1;
    setCurrentPage(last);
    setSearchParams({ ...currentParams(), page: `${last + 1}` });
    loadData(queryOn, orderBy, order, last);
  };

  const firstPage = (orderBy = "name", order: SortOrder = "asc") => {
    setCurrentPage(0);
    setSearchParams({ ...currentParams(), page: "1" });
    loadData(queryOn, orderBy, order, 0);
  };

  //load when page is loaded
  useEffect(() => {
    if (firstLoad) {
      loadData(queryOn, "", "asc");
    }
  }, [queryOn, firstLoad]);

  return {
    loading,
    data: vineyards,
    totalResults,
    totalPages,
    currentPage,
    loadData,
    firstPage,
    prevPage,
    nextPage,
    lastPage,
  };
}

export const VineyardsTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const [sortByField, setSortByField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("none");

  const queryOn = () => ({
    text: searchParams.get("search") || "",
    age: searchParams.get("age") || "",
    country: searchParams.get("country") || "",
    variety: searchParams.get("variety") || ""
  });

  const {
    loading,
    data: tableData,
    totalResults,
    totalPages,
    currentPage,
    loadData,
    nextPage,
    lastPage,
    prevPage,
    firstPage,
  } = useVineyardsLoader(queryOn());

  const runTextQuery = () => {
    setSearchParams({ search: searchText });
    loadData({ text: searchText });
  };

  const reloadAfterSortOrderChange = (field: string, sortOrder: SortOrder) => {
    const so = field === sortByField ? sortOrder : "asc"; //default to asc when the sort by field changed
    setSortByField(field);
    setSortOrder(so);
    loadData(queryOn(), field, so);
  };

  useEffect(() => {
    // Set the title when the component mounts
    document.title = 'Old Vine Registry Results';
    
    // Clean up the title when the component unmounts
    return () => {
      document.title = 'Old Vine Registry';
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    
    <div className="content-panel">
      <span className="title-large">
        {loading
          ? "searching for"
          : `${totalResults} result${totalResults === 1 ? "" : "s"} for`}{" "}
        {searchParams.has("search")
          ? `"${searchParams.get("search")}"`
          : searchParams.has("age")
            ? `age ${searchParams.get("age")} years`
            : searchParams.has("country")
              ? `vineyards from ${searchParams.get("country")}`
              : `vineyards with ${searchParams.get("variety")}`
        }
      </span>

      <Box display="flex" mt="20px">
        <input
          type="text"
          placeholder="Enter a vineyard name, winery name, region, grape variety, etc"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && searchText) {
              runTextQuery();
            }
          }}
          className="input-text search-input"
        />
        <button
          className="button u-ml-small search-button"
          disabled={!searchText}
          onClick={runTextQuery}
        >
          <span className="button-text">Search again</span>
        </button>
      </Box>

      {tableData.length === 0 && !loading && (
        <SearchTips
          className="u-mt-large"
          searchCountry={(country) => {
            setSearchText("");
            setSearchParams({ search: country });
            loadData({ country: country });
          }}
          searchVariety={(variety) => {
            setSearchText("");
            setSearchParams({ search: variety });
            loadData({ variety: variety });
          }}
          searchAgeRange={(ageRange) => {
            setSearchText("");
            setSearchParams({ age: ageRange });
            loadData({ age: ageRange });
          }}
        />
      )}

      <Loading loading={loading} />
      {tableData.length > 0 && (
        <>
          <Table
            hover
            size="sm"
            className="vineyard-table input-text u-mt-large"
          >
            <thead>
              <tr>
                <TableHeader
                  field="name"
                  sortOrder={sortOrder}
                  sortByField={sortByField}
                  onOrderChange={reloadAfterSortOrderChange}
                >
                  Vineyard name
                </TableHeader>
                <TableHeader
                  field="country"
                  sortOrder={sortOrder}
                  sortByField={sortByField}
                  onOrderChange={reloadAfterSortOrderChange}
                >
                  Country
                </TableHeader>
                <Media>
                  {({ currentBreakpoint }) => {
                    return (
                      currentBreakpoint === "desktop" && (
                        <>
                          <TableHeader
                            field="region"
                            sortOrder={sortOrder}
                            sortByField={sortByField}
                            onOrderChange={reloadAfterSortOrderChange}
                          >
                            Region
                          </TableHeader>
                          <TableHeader
                              field="subRegion"
                              sortOrder={sortOrder}
                              sortByField={sortByField}
                              onOrderChange={reloadAfterSortOrderChange}
                          >
                            Subregion
                          </TableHeader>
                          <TableHeader
                              field="subSubRegion"
                              sortOrder={sortOrder}
                              sortByField={sortByField}
                              onOrderChange={reloadAfterSortOrderChange}
                          >
                            Sub-Subregion
                          </TableHeader>
                          <TableHeader
                            field="yearPlanted"
                            sortOrder={sortOrder}
                            sortByField={sortByField}
                            onOrderChange={reloadAfterSortOrderChange}
                          >
                            Date planted
                          </TableHeader>
                        </>
                      )
                    );
                  }}
                </Media>
                <TableHeader
                  field="ageOfVines"
                  sortOrder={sortOrder}
                  sortByField={sortByField}
                  onOrderChange={reloadAfterSortOrderChange}
                >
                  Age
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row, i) => (
                <tr key={i}>
                  <TD>
                    <a
                      href={`${Paths.vineyard}?id=${row.composite}`}
                      target="_blank" // Open in a new tab
                      rel="noopener noreferrer" // Added for security
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        navigate(`${Paths.vineyard}?id=${row.composite}`);
                      }}
                    >
                      <span className="search-link">{row.name}</span>
                    </a>
                  </TD>
                  <TD>{row.country}</TD>
                  <Media>
                    {({ currentBreakpoint }) => {
                      return (
                        currentBreakpoint === "desktop" && (
                          <>
                            <TD>{row.region}</TD>
                            <TD>{row.subRegion}</TD>
                            <TD>{row.subSubRegion}</TD>
                            <TD>{row.yearPlanted}</TD>
                          </>
                        )
                      );
                    }}
                  </Media>
                  <TD>{row.ageOfVines}</TD>
                </tr>
              ))}
            </tbody>
          </Table>

          {totalPages > 1 && (
            <Box display="flex" alignItems="center" mt="20px">
              <span className="description-text">
                Page {currentPage + 1} of {totalPages}
              </span>
              <Pagination className="u-ml-medium">
                <Pagination.First
                  onClick={() => firstPage(sortByField, sortOrder)}
                  disabled={currentPage === 0}
                />
                <Pagination.Prev
                  onClick={() => prevPage(sortByField, sortOrder)}
                  disabled={currentPage === 0}
                />
                <Pagination.Next
                  onClick={() => nextPage(sortByField, sortOrder)}
                  disabled={currentPage === totalPages - 1}
                />
                <Pagination.Last
                  onClick={() => lastPage(sortByField, sortOrder)}
                  disabled={currentPage === totalPages - 1}
                />
              </Pagination>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
